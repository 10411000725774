import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Stack, Typography } from '@mui/material';
import { insertColor, insertOmbre } from '../../../../redux/slices/prothese/protheseSlice';
import { dispatch, useSelector } from '../../../../redux/store';
import ProtheseDescription from './ProtheseDescription';
export default function RightOptionsSection({ options, filter, currentPrice, selectedDent, }) {
    const prothese = useSelector((state) => state.dent);
    return (_jsxs(Card, { sx: {
            marginTop: '15px',
            width: '100%',
            overflow: 'auto',
            height: '600px',
            backgroundColor: 'transparent !important',
            border: '1px solid lightgray',
            padding: '0 15px',
        }, children: [_jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { marginTop: '20px', fontWeight: 'bold', marginBottom: '10px' }, children: "Prix :" }), _jsx(Typography, { variant: "h3", color: 'text.secondary', sx: {
                    fontWeight: 'bold',
                    border: '1px solid #637381',
                    borderRadius: '10px',
                    padding: '0px 20px',
                    width: 'fit-content',
                }, children: currentPrice }), _jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { marginTop: '20px', fontWeight: 'bold', marginBottom: '10px' }, children: "Option et param\u00E8tres :" }), _jsx(Card, { sx: { display: 'flex', gap: '20px', padding: 2, flexWrap: 'wrap' }, children: options?.map((option) => {
                    const dent = prothese.dents.find((dent) => dent.name === selectedDent.toString());
                    return option?.options?.map((op, index) => {
                        return (_jsxs(Stack, { sx: {
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                width: '40px',
                                cursor: 'pointer',
                                border: dent?.color === op?.color ? '1px solid #888' : '',
                                padding: '5px',
                                borderRadius: '8px',
                            }, onClick: () => {
                                dispatch(insertColor({ name: selectedDent.toString(), color: op?.color }));
                                dispatch(insertOmbre({ name: selectedDent.toString(), ombre: op?.name }));
                            }, children: [_jsx("svg", { width: "29", height: "34", viewBox: "0 0 29 34", fill: op?.color, children: _jsx("path", { d: "M4.93713 27.9468L4.93722 27.9471C6.08329 30.9643 8.34373 32.4013 10.3397 33.0863C12.2584 33.7448 13.9239 33.7049 14.1022 33.7007C14.1096 33.7005 14.1145 33.7004 14.1166 33.7004C14.1185 33.7004 14.1229 33.7005 14.1297 33.7006C14.2984 33.7048 15.9638 33.7458 17.8854 33.0863C19.8815 32.4013 22.1446 30.9642 23.2961 27.9471C23.3575 27.7854 23.4232 27.6132 23.4927 27.4312C24.7564 24.1201 27.2644 17.5489 27.9146 11.6768C28.2578 8.57737 28.0798 5.69959 26.9519 3.60224C25.8327 1.52108 23.7637 0.182331 20.2505 0.182331L20.2497 0.182327L14.1274 0.150005H14.1266H14.1058H14.085H14.0842L7.96193 0.182331H7.96114C4.45074 0.182331 2.38374 1.52103 1.26622 3.60216C0.139982 5.69951 -0.0366757 8.5773 0.307852 11.6767C0.963529 17.5753 3.49385 24.1794 4.75682 27.4757C4.82025 27.6413 4.88049 27.7985 4.93713 27.9468Z", fill: op?.color, stroke: "black", "stroke-width": "0.3" }) }), _jsx(Typography, { variant: "body1", color: 'text.secondary', sx: { textAlign: 'center' }, children: op?.name })] }));
                    });
                }) }), _jsx(ProtheseDescription, { selectedDent: selectedDent })] }));
}
